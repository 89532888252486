 const MoviesActionType={
  SET_MOVIE_DATA:'SET_MOVIE_DATA',
  SET_MOVIE_DATA_ORIGIN:'SET_MOVIE_DATA_ORIGIN',
  SET_MOVIE_DATA_POPULAR:'SET_MOVIE_DATA_POPULAR',
  SET_MOVIE_DATA_SUCCESS:'SET_MOVIE_DATA_SUCCESS' ,
  SET_MOVIE_ADDITIONAL_DATA :'  SET_MOVIE_ADDITIONAL_DATA ',
  REMOVE_MOVIE_ADDITIONAL_DATA:'REMOVE_MOVIE_ADDITIONAL_DATA',
  ADD_PLAY_MOVIE:'  ADD_PLAY_MOVIE',
  PLAY_MOVIE_LIST:'GET_MOVIE_PURCHASED',
  FETCH_PURCHASE_MOVIE:'FETCH_PURCHASE_MOVIE',
  GET_BANNER_LIST:'GET_BANNER_LIST'

}
export default MoviesActionType