import React from 'react'
import './footer.css'
function Footer() {
    return (
        <div>
            <div className="footer_container">
                    {/* <div className="footer_para"> */}
                        <p>Design And Developed by <a href="https://www.instagram.com/mohd.shihab_/">shihab</a></p>
                    {/* </div> */}
            </div>
        </div>
    )
}

export default Footer
